<template>
    <div>
        <div class="o-offcanvas__overlay"></div>

        <div class="o-offcanvas"
            :class="offcanvasClass">
            <button class="o-offcanvas__close" type="button" aria-label="Close panel" @click="toggleMenu"></button>
            <PartialsMenu v-if="!useMainStore().contactMenu" />
            <HubspotContactForm v-else :hubspot="useMainStore().contactFormType" :theme="'obsidian'" />
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '~/store';

const toggleMenu = () => {
    offcanvasClass.value = '';
    setTimeout(() => {
        useMainStore().toggleMenu();
        useMainStore().closeContact();
    }, 500);
};

const offcanvasClass = ref('');

onMounted(() => {
    setTimeout(() => {
        offcanvasClass.value = 'is-active';
    }, 100);
});
</script>
