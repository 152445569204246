<template>
    <header
        class="o-header js-header"
        :class="{
            'o-header--beta': theme == 'dark' || useMainStore().isSearchOpen || isBeta,
            'is-search-active' : useMainStore().isSearchOpen,
            'is-sticky' : isSticky,
            'is-visible' : isVisible
        }">

        <div class="o-header__upper">
            <div class="grid-container">
                <div class="o-header__accessibility-container">
                    <div class="grid-container full">
                        <div class="grid-x grid-margin-x">
                            <div class="small-12 cell">
                                <button class="o-header__accessibility" type="button">
                                    Accessibility settings
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid-x grid-margin-x align-middle">
                    <div class="small-6 medium-2 cell">
                        <a href="/" class="o-header__logo" title="Visit homepage">
                            <SvgoLogo />
                        </a>
                    </div>

                    <div class="small-6 medium-9 medium-offset-1 cell">
                        <div class="o-header__primary-wrapper" v-if="$getGlobalContent()">
                            <nav class="o-header__primary-nav" v-if="$getGlobalContent().headerLinks && $getGlobalContent().headerLinks.length">
                                <ul>
                                    <li v-for="(parentLink, index) in $getGlobalContent().headerLinks"
                                        class="js-header__primary-nav-parent"
                                        :class="{ 'has-children': parentLink.childLinks }">
                                        <NuxtLink :to="$convertUrl(parentLink.link.url)" class="o-header__primary-nav-link" type="button" v-if="parentLink.childLinks" @mouseover="activateDropdown($event)">{{parentLink.link.title}}</NuxtLink>
                                        <NuxtLink :to="$convertUrl(parentLink.link.url)" class="o-header__primary-nav-link" v-else>
                                            <span v-html="parentLink.link.title"></span>
                                        </NuxtLink>
                                        <ul class="o-header__child-links" v-if="parentLink.childLinks">
                                            <li>
                                                <NuxtLink :to="$convertUrl(parentLink.link.url)" class="o-header__primary-nav-link">
                                                    <span v-html="parentLink.link.title"></span>
                                                </NuxtLink>
                                            </li>

                                            <li v-for="(link, index) in parentLink.childLinks">
                                                <NuxtLink :to="$convertUrl(link.link.url)" class="o-header__primary-nav-link">
                                                    <span v-html="link.link.title"></span>
                                                </NuxtLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>

                            <button class="o-header__featured-link" type="button" @click="toggleMenuAndIsContactMenu">
                                Connect with us
                            </button>

                            <button class="o-header__action-button is-search" type="button" aria-label="Search" @click="toggleSearch">
                                <SvgoIconClose v-if="searchActive" />
                                <SvgoIconSearch v-else />
                            </button>

                            <button class="o-header__action-button is-menu" type="button" aria-label="More links" @click="toggleMenu">
                                <SvgoIconMenu />
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="o-header__lower">
            <div class="grid-container full">
                <GlobalSearch />
            </div>
        </div>
    </header>
</template>

<script setup>
    import { useMainStore } from '~/store';

    const isSticky = useState('isSticky', () => false);
    const isBeta = useState('isBeta', () => false);
    const isVisible = useState('isVisible', () => false);

    const toggleMenuAndIsContactMenu = () => {
        toggleMenu();
        isContactMenu();
    }

    const isContactMenu = () => {
        useMainStore().openContact('global', useMainStore().globalData.globalContent.globalContent.hubspotContactFormId);
    }

    const toggleMenu = () => {
        useMainStore().toggleMenu();
    };

    const toggleSearch = () => {
        useMainStore().toggleSearch();
    };

    const props = defineProps({
        theme: {
            type: String,
            required: true
        },
        searchActive: {
            type: Boolean,
            required: false
        }
    });

    const activateDropdown = (event) => {
        const $target = event.target;
        const $parent = $target.parentNode;

        if ($parent.classList.contains('is-active')) {
            $parent.classList.remove('is-active');
        } else {
            document.querySelectorAll('.js-header__primary-nav-parent').forEach((el) => {
                el.classList.remove('is-active');
            });

            $parent.classList.add('is-active');
        };
    };

    let scrollY = 0;

    const handleScroll = () => {
        const $header = document.querySelector('.js-header');

        if (window.scrollY === 0) {
            isSticky.value = false;
            isVisible.value = false;

            if (useMainStore().headerTheme != 'dark' && !useMainStore().isSearchOpen) {
                isBeta.value = false;
            }
        } else if (scrollY < window.scrollY) {
            isSticky.value = true;
            isBeta.value = true;

            $header.querySelectorAll('.o-header__primary-nav li').forEach((el) => {
                if(el.classList.contains('is-active')) {
                    el.classList.remove('is-active');
                };
            });

            if(!useMainStore().isSearchOpen) {
                isVisible.value = false;
            }
        } else {
            isVisible.value = true;
        };

        scrollY = window.scrollY;
    };

    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>
