<template>
    <div
        class="c-search"
        :class="useMainStore().searchActiveClass">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="c-search__wrapper">
                        <form class="c-search__form" method="GET" action="/search">
                            <input
                                class="c-search__input js-search__input"
                                type="search"
                                name="query"
                                id="query"
                                aria-label="Search input field"
                                placeholder="Search Cisilion"
                                ref="searchInput"
                                v-model="searchValue"
                                @click="toggleSuggestedSearches = !toggleSuggestedSearches" />

                            <button
                                class="c-search__button js-search__submit"
                                :class="useMainStore().searchActiveButtonClass"
                                type="submit"
                                aria-label="Search">
                                <SvgoIconSearch />
                            </button>
                        </form>

                        <div
                            v-if="$getGlobalContent().suggestedSearches"
                            class="c-search__suggested-searches js-search__suggested-searches"
                            :class="toggleSuggestedSearches == true ? 'is-active' : null">
                            <div class="c-search__suggested-searches-title">
                                Suggested searches:
                            </div>

                            <button
                                v-for="(query, index) in $getGlobalContent().suggestedSearches"
                                :key="index"
                                class="c-search__suggested-search"
                                type="button"
                                @click="runSuggestedSearch(query.searchQuery)">
                                {{ query.searchQuery }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useMainStore } from '~/store';

    const searchValue = ref(null);
    const toggleSuggestedSearches = ref(false);

    const runSuggestedSearch = (query) => {
        const $submit = document.querySelectorAll('.js-search__submit');

        // Set search input value to selected query
        searchValue.value = query;

        // Submit search form after a few milliseconds
        setTimeout(() => {
            $submit[0].click();
        }, 250);
    };
</script>
