
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'

import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'


dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(utc)


dayjs.tz.setDefault('Europe/London')



export default dayjs
