const eventsQuery = (postTime = String, postsPerPage = Number, offset = Number, activeFilters) => (
    `query Events {
        events(
            where: {
                show${postTime}Events: true,
                offsetPagination: {size: ${postsPerPage}, offset: ${offset}},
                activeFilters: ${activeFilters}
            }
        ) {
            edges {
                node {
                    slug
                    title
                    date
                    event {
                        excerpt
                        eventDate
                        eventLocation
                        featuredImage {
                            sizes(size: LARGE)
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        video
                        author {
                            ... on CisilionAuthor {
                                title
                                author {
                                    company
                                    linkedinProfile
                                    position
                                    featuredImage {
                                        sizes(size: MEDIUM)
                                        sourceUrl(size: MEDIUM)
                                        srcSet(size: MEDIUM)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            pageInfo {
                total
            }
        }
    }`
);

export default eventsQuery;
