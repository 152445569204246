<template>
    <div class="c-hubspot">

    <HubspotHeader v-if="!hubsId && !sent" />

    <div class="c-hubspot-form" v-if="loaded" :class="isDarkBackground ? 'has-dark-background' : ''">

        <div v-if="sent">
            <HubspotSent :successMessage="successMessage" :enteredData="enteredData" />
        </div>

        <div class="c-message is-error" v-if="errors && errors.length">
            <p v-for="(error,i) in errors" :key="i">
                {{ error }}
            </p>
        </div>

        <form
            v-if="!sent"
            class="c-hubspot-form__form"
            :class="formData.cssClass"
            :action="formData.action"
            :method="formData.method"
            @submit.prevent="handleSubmit"
            >
            <div class="utl-clearfix">
                <div
                    v-for="(fieldGroup, groupIndex) in formData.formFieldGroups"
                    :key="groupIndex"
                    class="c-hubspot-form__field">
                    <template
                        v-for="field in fieldGroup.fields"
                        :key="field.name">

                        <div
                            v-if="!field.hidden"
                            class="c-hubspot-form__field-wrapper">
                            <label
                                v-if="!field.labelHidden"
                                :for="formatFieldName(field)">
                                {{ field.label }}

                                <span
                                    v-if="field.required"
                                    class="is-required">
                                    *
                                </span>
                            </label>

                            <!-- Handle text, phonenumber, and textarea fields -->
                            <input
                                v-if="field.fieldType === 'text' || field.fieldType === 'phonenumber'"
                                :type="field.fieldType === 'phonenumber' ? 'tel' : field.fieldType"
                                :name="formatFieldName(field)" :placeholder="field.placeholder"
                                :required="field.required" :value="getFieldValue(formatFieldName(field))"
                                @input="updateFieldValue(formatFieldName(field), $event.target.value)" />

                            <textarea v-if="field.fieldType === 'textarea'"
                                :name="formatFieldName(field)"
                                :placeholder="field.placeholder"
                                :required="field.required"
                                :value="getFieldValue(formatFieldName(field))"
                                @input="updateFieldValue(formatFieldName(field), $event.target.value)">
                            </textarea>

                            <!-- Handle date fields -->
                            <input v-if="field.fieldType === 'date'"
                                type="date"
                                :name="formatFieldName(field)"
                                :required="field.required"
                                :value="getFieldValue(formatFieldName(field))"
                                @input="updateFieldValue(formatFieldName(field), $event.target.value)">

                            <!-- Handle select fields -->
                            <select v-if="field.fieldType === 'select'"
                                :name="formatFieldName(field)"
                                :required="field.required"
                                :value="getFieldValue(formatFieldName(field))"
                                @input="updateFieldValue(formatFieldName(field), $event.target.value)">
                                <option value="" selected disabled hidden>Please select&hellip;</option>

                                <option v-for="(option, optionIndex) in field.options" :key="optionIndex" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>

                             <!-- Handle radio buttons -->
                            <div v-if="field.fieldType === 'radio'">
                                <label class="is-input-wrapper" v-for="(option, optionIndex) in field.options" :key="optionIndex">
                                    <input type="radio"
                                        :name="formatFieldName(field)"
                                        :value="option.value"
                                        @input="handleRadioChange(field.name, $event.target.value)" />
                                    <span>{{ option.label }}</span>
                                    <span
                                        v-if="field.required"
                                        class="is-required">
                                        *
                                    </span>
                                </label>
                            </div>

                            <!-- Handle checkboxes -->
                            <div v-if="field.fieldType === 'checkbox'" >
                                <label class="is-input-wrapper" v-for="(option, optionIndex) in field.options" :key="optionIndex">
                                    <input
                                        type="checkbox"
                                        :name="formatFieldName(field)"
                                        :value="option.value"
                                        :checked="isCheckboxChecked(field.name, option.value)"
                                        @change="handleCheckboxChange(field.name, option.value)" />
                                    <span>{{ option.label }}</span>
                                    <span
                                        v-if="field.required"
                                        class="is-required">
                                        *
                                    </span>
                                </label>
                            </div>

                            <!-- Handle files -->
                            <div v-if="field.fieldType === 'file'" >
                                <input name="upload" type="file" ref="fileInput" @change="handleFileUpload(field.name, $event)" />
                            </div>
                        </div>
                    </template>
                </div>
                <!-- W I N N I E T H E P O O -->
                <div
                    v-for="(fieldGroup, groupIndex) in formData.formFieldGroups"
                    :key="groupIndex"
                    class="c-hubspot-form__field c-hubspot-form__field--hp-field">
                    <template
                        v-for="field in fieldGroup.fields"
                        :key="field.name">
                        <div
                            v-if="!field.hidden"
                            class="c-hubspot-form__field-wrapper">
                            <label
                                v-if="!field.labelHidden"
                                :for="field.name">
                                {{ field.label }}

                                <span
                                    v-if="field.required"
                                    class="is-required">
                                    *
                                </span>
                            </label>

                            <!-- Handle text, phonenumber, and textarea fields -->
                            <input
                                v-if="field.fieldType === 'text' || field.fieldType === 'phonenumber'"
                                :type="field.fieldType === 'phonenumber' ? 'tel' : field.fieldType"
                                :name="formatFieldName(field)" :placeholder="field.placeholder"
                                :value="getHPFieldValue(field.name)"
                                @input="updateHPFieldValue(field.name, $event.target.value)" />

                            <textarea v-if="field.fieldType === 'textarea'"
                                :name="formatFieldName(field)"
                                :placeholder="field.placeholder"
                                :value="getHPFieldValue(field.name)"
                                @input="updateHPFieldValue(field.name, $event.target.value)">
                            </textarea>

                            <!-- Handle date fields -->
                            <input v-if="field.fieldType === 'date'"
                                type="date"
                                :name="formatFieldName(field)"
                                :value="getHPFieldValue(field.name)"
                                @input="updateHPFieldValue(field.name, $event.target.value)">

                            <!-- Handle select fields -->
                            <select v-if="field.fieldType === 'select'"
                                :name="formatFieldName(field)"
                                :value="getHPFieldValue(field.name)"
                                @input="updateHPFieldValue(field.name, $event.target.value)">
                                <option value="" selected disabled hidden>Please select&hellip;</option>

                                <option v-for="(option, optionIndex) in field.options" :key="optionIndex" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>

                             <!-- Handle radio buttons -->
                            <div v-if="field.fieldType === 'radio'">
                                <label class="is-input-wrapper" v-for="(option, optionIndex) in field.options" :key="optionIndex">
                                    <input type="radio"
                                        :name="formatFieldName(field)"
                                        :value="option.value"
                                        @input="handleRadioChange(field.name, $event.target.value)" />
                                    <span>{{ option.label }}</span>
                                    <span
                                        v-if="field.required"
                                        class="is-required">
                                        *
                                    </span>
                                </label>
                            </div>

                            <!-- Handle checkboxes -->
                            <div v-if="field.fieldType === 'checkbox'" >
                                <label class="is-input-wrapper" v-for="(option, optionIndex) in field.options" :key="optionIndex">
                                    <input
                                        type="checkbox"
                                        :name="formatFieldName(field)"
                                        :value="option.value"
                                        :checked="isCheckboxChecked(field.name, option.value)"
                                        @change="handleCheckboxChange(field.name, option.value)" />
                                    <span>{{ option.label }}</span>
                                    <span
                                        v-if="field.required"
                                        class="is-required">
                                        *
                                    </span>
                                </label>
                            </div>

                            <!-- Handle files -->
                            <div v-if="field.fieldType === 'file'" >
                                <input :name="formatFieldName(field)" type="file" ref="fileInput" @change="handleFileUpload(field.name, $event)" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-if="metaData.communicationConsentText && !metaData.isLegitimateInterest">
                <span v-html="metaData.communicationConsentText" class="c-hubspot-form__description-text"></span>
            </div>

            <div v-if="metaData.communicationConsentCheckboxes && !metaData.isLegitimateInterest">
                <div v-for="(checkbox, checkboxIndex) in metaData.communicationConsentCheckboxes" :key="checkboxIndex">
                    <label class="is-input-wrapper">
                        <input
                            type="checkbox"
                            :value="checkbox.communicationTypeId"
                            :required="checkbox.required"
                            @change="updateCommunicationConsent(checkbox.communicationTypeId, checkbox.label)"
                        />
                        {{ checkbox.label }}
                    </label>
                    <span class="c-hubspot-form__description-text" v-if="!metaData.isLegitimateInterest && metaData.processingConsentType === 'IMPLICIT'">
                        <span v-html="metaData.privacyPolicyText"></span>
                    </span>
                    <span class="c-hubspot-form__description-text" v-if="metaData.processingConsentType != 'IMPLICIT'">
                        <span v-html="metaData.processingConsentText"></span>
                    </span>
                </div>

            </div>

            <!-- Processing Info -->
            <div v-if="metaData.processingConsentType === 'REQUIRED_CHECKBOX'">
                <div v-if="metaData.processingConsentText" >
                    <label class="is-input-wrapper">
                        <input
                            type="checkbox"
                            v-model="legalConsentOptions.consent.consentToProcess"
                            required
                        />
                        {{ metaData.processingConsentCheckboxLabel }}
                    </label>
                </div>

                <span class="c-hubspot-form__description-text" v-if="!metaData.isLegitimateInterest">
                    <span v-html="metaData.privacyPolicyText"></span>
                </span>
            </div>

            <span class="c-hubspot-form__description-text" v-if="metaData.isLegitimateInterest">
                <span v-html="metaData.privacyPolicyText"></span>
            </span>
            <span class="c-hubspot-form__description-text" v-if="metaData.processingConsentType === 'IMPLICIT' && !metaData.isLegitimateInterest">
                <span v-html="metaData.processingConsentText"></span>
            </span>

            <button class="c-hubspot-form__submit" type="submit">
                {{ formData.submitText }}
            </button>
        </form>
    </div>
    <HubspotLoading v-else />
    </div>
</template>

<style>
    .loading-container {
        margin: auto;
        height: 200px;
        width: 200px;
        font-size: 4rem;
        text-align: center;
    }

    .spinner {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>

<script>
    import { useMainStore } from '~/store';

    export default {
        data() {
            return {
                enteredData:{
                    fields:[],
                    hp: []
                },
                fileUpload: null,
                filename: null,
                fileField: null,
                mimetype: null,
                formData: {},
                metaData: {},
                legalConsentOptions: {
                    consent: {
                        consentToProcess: true,
                        text: '',
                        communications: [],
                    },
                },
                errors: [],
                successMessage: null,
                sent: false,
                loaded: false,
                logo: '../assets/icons/logo.svg',
            }
        },
        props: {
            hubsId: {
                type: String,
                required: false
            },
            theme: {
                type: String,
                required: true
            }
        },
        computed: {
            isDarkBackground() {
                let darkThemes = ['obsidian', 'teal'];
                let currentTheme = this.theme;

                return darkThemes.includes(currentTheme); 
            }
        },
        methods: {
            formatFieldName(field) {
                if (field.objectTypeId) {
                    return `${field.objectTypeId}/${field.name}`;
                }
                return field.name;
            },
            getFormLocationType() {
                // Get current dom element of Vue component
                const currentComponent = this.$el;

                // if currentComponent is in offcanvas
                if (currentComponent.closest('.o-offcanvas.is-active')) {
                    return 'offcanvas';
                }

                // if in .c-form
                if (currentComponent.closest('.c-form')) {
                    return 'component';
                }

                return null;
            },
            analyticsSuccessEvent() {
                window.dataLayer = window.dataLayer || [];

                window.dataLayer.push({
                    event: 'form_success',
                    form_location: this.getFormLocationType()
                });
            },
            async handleFileUpload(fieldName, event) {
                const file = event.target.files[0];
                this.fileUpload = file;
                this.filename = this.fileUpload.name;
                this.fileField = fieldName
                this.mimetype = this.fileUpload.type
            },
            async uploadFileToAPI() {
                const hasFileField = this.formData.formFieldGroups.some(group =>
                    group.fields.some(field => field.fieldType === 'file')
                );

                if (!hasFileField) {
                    console.log('No file fields present in the form. Skipping file upload.');
                    
                    return;
                }

                if (!this.fileUpload) {
                    console.warn('File upload is required but no file has been selected.');

                    return;
                }

                const fileOptions = {
                    access: 'PUBLIC_INDEXABLE',
                };

                const file = this.fileUpload; // Access the file object
                const fileBlob = new Blob([file], { type: file.type });

                const base64Data = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(fileBlob);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = (error) => reject(error);
                });

                const object = {
                    filename: this.filename,
                    mimetype: this.mimetype,
                    fileContent: base64Data,
                    options: fileOptions,
                    folderPath: 'docs'
                };

                try {
                    const response = await fetch('/api/hubspot/upload/file/', {
                        method: 'POST',
                        body: JSON.stringify(object),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    // if (!response.ok) {
                    //   throw new Error(`Request failed with status: ${response.status}`);
                    // }

                    const data = await response.json();
                    
                    console.log('File uploaded successfully:', data);

                    if (data && data.objects && data.objects.length > 0) {
                        const uploadedFile = data.objects[0]; // Assuming the response has an array of 'objects'

                        const { friendly_url } = uploadedFile;

                        // If the field doesn't exist, add it to the fields array
                        this.enteredData.fields.push({ 
                            name: this.fileField, 
                            value: friendly_url 
                        }); // Replace 'new value' with the new value
                    }
                    // Handle the response data as needed
                } catch (error) {
                    console.error('Error uploading file:', error);
                    // Handle errors
                }
            },
            updateCommunicationConsent(communicationTypeId, label) {
                const communication = {
                    value: true,
                    subscriptionTypeId: communicationTypeId,
                    text: label,
                };

                // Check if communication exists, and remove if already added
                const index = this.legalConsentOptions.consent.communications.findIndex(
                    comm => comm.subscriptionTypeId === communicationTypeId
                );

                if (index !== -1) {
                    this.legalConsentOptions.consent.communications.splice(index, 1);
                } else {
                    this.legalConsentOptions.consent.communications.push(communication);
                }
            },

            getFieldValue(fieldName) {
                // Get the field object for the given field name
                const fieldObj = this.enteredData.fields.find((field) => field.name === fieldName);

                return fieldObj ? fieldObj.value : '';
            },

            updateFieldValue(fieldName, value) {
                // Find the index of the field object with the given field name
                const fieldIndex = this.enteredData.fields.findIndex((field) => field.name === fieldName);

                if (fieldIndex !== -1) {
                    // If the field exists, update the value
                    this.enteredData.fields[fieldIndex].value = value;
                } else {
                    // If the field doesn't exist, add a new object to the array
                    this.enteredData.fields.push({ name: fieldName, value });
                }
            },

            getHPFieldValue(fieldName) {
                // Get the field object for the given field name
                const fieldObj = this.enteredData.hp ? this.enteredData.hp.find((field) => field.name === fieldName) : null;

                return fieldObj ? fieldObj.value : '';
            },

            updateHPFieldValue(fieldName, value) {
                // Find the index of the field object with the given field name
                const fieldIndex = this.enteredData.hp.findIndex((field) => field.name === fieldName);

                if (fieldIndex !== -1) {
                    // If the field exists, update the value
                    this.enteredData.hp[fieldIndex].value = value;
                } else {
                    // If the field doesn't exist, add a new object to the array
                    if(value && value !== ''){
                        this.enteredData.hp.push({ name: fieldName, value });
                    }
                }
            },

            isCheckboxChecked(fieldName, value) {
                // Find the field object in the "enteredData.fields" array based on the field name
                const fieldObj = this.enteredData.fields.find((field) => field.name === fieldName);

                if (!fieldObj) {
                    // If the field doesn't exist, return false (checkbox unchecked)
                    return false;
                }

                // Check if the option value is present in the field's "value" array
                return Array.isArray(fieldObj.value) && fieldObj.value.includes(value);
            },

            handleRadioChange(fieldName, value) {
                const existingFieldIndex = this.enteredData.fields.findIndex(
                    (field) => field.name === fieldName
                );

                if (existingFieldIndex !== -1) {
                    // If the field already exists, update its value
                    this.enteredData.fields[existingFieldIndex].value = value;
                } else {
                    // If the field doesn't exist, add a new object for it in the "fields" array
                    this.enteredData.fields.push({ name: fieldName, value: value });
                }
            },

            handleCheckboxChange(fieldName, value) {
                const existingIndex = this.enteredData.fields.findIndex(field => field.name === fieldName && field.value === value);

                if (existingIndex !== -1) {
                    // If the value exists, remove it
                    this.enteredData.fields.splice(existingIndex, 1);
                } else {
                    // Otherwise, add a new object with this field name and value
                    this.enteredData.fields.push({ name: fieldName, value: value });
                }
            },


            isValidEmail(email) {
                // Regular expression for validating email addresses
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                return emailRegex.test(email);
            },

            getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            },

            errorCheck() {
                this.errors = []; // Clear the errors array before performing checks

                // Check for required fields
                for (const fieldGroup of this.formData.formFieldGroups) {
                    for (const field of fieldGroup.fields) {
                        const formattedFieldName = this.formatFieldName(field);

                        if (field.required) {
                            const value = this.getFieldValue(formattedFieldName);
                            const isUploadField = formattedFieldName.includes('upload');

                            if (isUploadField) {
                                if (!value || value === '') {
                                //this.errors.push(`${field.label} is required.`);
                                }
                            } else {
                                if (!value) {
                                this.errors.push(`${field.label} is required.`);
                                }
                            }
                        }
                    }
                }

                // Email Validation
                let emailField = null;

                for (const group of this.formData.formFieldGroups) {
                    emailField = group.fields.find(field => field.name == 'email');

                    console.log(emailField);

                    if (emailField) break;
                }

                if (emailField) {
                    const emailValue = this.getFieldValue(this.formatFieldName(emailField));

                    console.log(emailValue);

                    if (emailValue && !this.isValidEmail(emailValue)) {
                        this.errors.push('Invalid email address.');
                    }
                }
            },

            async handleSubmit() {
                // They fell for it...
                if(this.enteredData.hp.length){
                    console.log('gotcha');
                    return;
                }

                // HubSpot hubspotutk cookie
                const hubspotutk = this.getCookie('hubspotutk');

                await this.uploadFileToAPI();

                // Reset the errors object
                this.errorCheck()

                if(this.errors.length){
                    return
                }

                this.enteredData.legalConsentOptions = this.legalConsentOptions;

                try {
                    // Prepare the request body in the required format
                    const config = useRuntimeConfig();
                    const formId = this.hubsId ? this.hubsId : useMainStore().hubspotId;
                    const apiKey = config.public.hubspotApiKey;

                    let ipData = await fetch('https://api.ipify.org?format=json');

                    ipData = await ipData.json();

                    const response = await fetch(
                        `https://api.hsforms.com/submissions/v3/integration/submit/${this.formData.portalId}/${formId}`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${apiKey}`, // Replace with your HubSpot API key
                            },
                            body: JSON.stringify({...this.enteredData, 
                                context: {
                                    hutk: hubspotutk, // Include the hubspotutk cookie in the request body
                                    ipAddress: ipData.ip,
                                    pageUri: window.location.href
                                }
                            })
                        }
                    );

                    
                    if (response.ok) {
                        // Form submission was successful
                        if(!this.formData?.inlineMessage){
                            this.successMessage = "Thank you for your response. A member of our team will be in touch shortly."
                        } else {
                            this.successMessage = this.formData.inlineMessage;
                        }
                        
                        this.sent = true
                        this.analyticsSuccessEvent();
                    } else {
                        // Form submission failed
                        console.error('Failed to submit the form to HubSpot', response);
                        this.sent = false
                        // Handle the error or show an error message to the user.
                        this.errors.push( "There was an error submitting your response. Please try again." )
                    }
                } catch (error) {
                    console.error('Error submitting the form to HubSpot:', error);
                    // Handle the error or show an error message to the user.
                }
            },

            async fetchFormData(formId) {
                try {
                    const response = await fetch(`/api/hubspot/${formId}`);

                    if (response.ok) {
                        const responseData = await response.json();

                        this.formData = responseData; // Assign the entire response to formData if needed


                        // Find the object with the name "legalConsentOptions"
                        const legalConsentOptions = responseData.metaData.find(item => item.name === 'legalConsentOptions');

                        if (legalConsentOptions) {
                            // Parse the value of legalConsentOptions and extract communicationConsentCheckboxes
                            this.metaData = JSON.parse(legalConsentOptions.value);
                        }
                    } else {
                        console.error('Failed to fetch form data');
                    }
                } catch (error) {
                    console.error('Error fetching form data:', error);
                } finally {
                    setTimeout(() => {
                        this.loaded = true;
                    }, 1500);
                }
            }
        },
        mounted() {
            if (this.hubsId) {
                this.fetchFormData(this.hubsId);
            } else {
                this.fetchFormData(useMainStore().hubspotId);
            }
        }
    }
</script>
