<template>
    <div>
        <nav class="o-header__primary-nav" v-if="$getGlobalContent() && $getGlobalContent().headerLinks && $getGlobalContent().headerLinks.length">
            <ul>
                <li v-for="(parentLink, index) in $getGlobalContent().headerLinks"
                    :class="{ 'has-children': parentLink.childLinks }">
                    <button class="o-header__primary-nav-link" type="button" v-html="parentLink.link.title" v-if="parentLink.childLinks" @click="activateDropdown($event)"></button>

                    <NuxtLink :to="$convertUrl(parentLink.link.url)" class="o-header__primary-nav-link" v-else>
                        <span v-html="parentLink.link.title"></span>
                    </NuxtLink>

                    <ul class="o-header__child-links" v-if="parentLink.childLinks">
                        <li @click="closeMenu">
                            <NuxtLink :to="$convertUrl(parentLink.link.url)" class="o-header__primary-nav-link">
                                <span v-html="parentLink.link.title"></span>
                            </NuxtLink>
                        </li>

                        <li
                            v-for="(link, index) in parentLink.childLinks"
                            @click="closeMenu">
                            <NuxtLink :to="$convertUrl(link.link.url)" class="o-header__primary-nav-link">
                                <span v-html="link.link.title"></span>
                            </NuxtLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

        <button
            class="o-header__featured-link"
            type="button"
            @click="toggleMobileContactForm">
            Connect with us
        </button>

        <nav class="o-offcanvas__nav" v-if="$getGlobalContent() && $getGlobalContent().offcanvasParentLinks">
            <ul>
                <li v-for="(parentLink, index) in $getGlobalContent().offcanvasParentLinks" @click="closeMenu">
                    <NuxtLink
                        v-if="parentLink.link.url != '#'"
                        :to="$convertUrl(parentLink.link.url)"
                        class="o-offcanvas__nav-link">
                        <span v-html="parentLink.link.title"></span>
                    </NuxtLink>

                    <span
                        v-else
                        class="o-offcanvas__nav-link">
                        <span v-html="parentLink.link.title"></span>
                    </span>

                    <ul v-if="parentLink.childLinks">
                        <li v-for="(link, index) in parentLink.childLinks">
                            <NuxtLink
                                v-if="link.link.url"
                                :to="$convertUrl(link.link.url)"
                                class="o-offcanvas__nav-link">
                                <span v-html="link.link.title"></span>
                            </NuxtLink>

                        </li>
                    </ul>
                </li>
            </ul>
        </nav>

        <div class="o-offcanvas__latest-event-wrapper" v-if="useMainStore().latestEvent.slug">
            <div class="o-offcanvas__latest-event-heading">
                Latest event
            </div>

            <NuxtLink
                :to="`/events/${useMainStore().latestEvent.slug}`"
                class="o-offcanvas__latest-event">
                <div
                    v-if="useMainStore().latestEvent.event.featuredImage"
                    class="o-offcanvas__latest-event-image">
                    <img
                        :srcset="useMainStore().latestEvent.event.featuredImage.srcSet"
                        :sizes="useMainStore().latestEvent.event.featuredImage.sizes"
                        :src="useMainStore().latestEvent.event.featuredImage.sourceUrl"
                        :title="useMainStore().latestEvent.title"
                        :alt="useMainStore().latestEvent.title" />
                </div>

                <div class="o-offcanvas__latest-event-title">
                    {{ useMainStore().latestEvent.title }}
                </div>
            </NuxtLink>
        </div>

        <div
            v-if="$getGlobalContent()"
            class="o-offcanvas__social">
            <NuxtLink :to="$getGlobalContent().linkedin" class="c-social-icon c-social-icon--linkedin" title="Follow Cisilion on LinkedIn" target="_blank" v-if="$getGlobalContent().linkedin">
                <SvgoIconLinkedin />
            </NuxtLink>

            <NuxtLink :to="$getGlobalContent().twitter" class="c-social-icon c-social-icon--x" title="Follow Cisilion on X" target="_blank" v-if="$getGlobalContent().twitter">
                <SvgoIconXCircle />
            </NuxtLink>

            <NuxtLink :to="$getGlobalContent().facebook" class="c-social-icon c-social-icon--facebook" title="Follow Cisilion on Facebook" target="_blank" v-if="$getGlobalContent().facebook">
                <SvgoIconFacebook />
            </NuxtLink>

            <NuxtLink :to="$getGlobalContent().youtube" class="c-social-icon c-social-icon--youtube" title="Follow Cisilion on YouTube" target="_blank" v-if="$getGlobalContent().youtube">
                <SvgoIconYoutube />
            </NuxtLink>

            <NuxtLink :to="$getGlobalContent().instagram" class="c-social-icon c-social-icon--instagram" title="Follow Cisilion on Instagram" target="_blank" v-if="$getGlobalContent().instagram">
                <SvgoIconInstagram />
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
    import { useMainStore } from '~/store';

    const closeMenu = () => {
        setTimeout(() => {
            useMainStore().toggleMenu();
        }, 500);
    };

    const toggleMobileContactForm = () => {
        useMainStore().toggleMenu();

        setTimeout(() => {
            useMainStore().toggleMenu();
            useMainStore().openContact('global', useMainStore().globalData.globalContent.globalContent.hubspotContactFormId);
        }, 500);
    };

    const activateDropdown = (event) => {
        const $target = event.target;
        const $parent = $target.parentNode;

        if ($parent.classList.contains('is-active')) {
            $parent.classList.remove('is-active');
        } else {
            $parent.classList.add('is-active');
        };
    };
</script>
