const globalContentQuery = `
query GlobalContent {
    readingSettings {
        postsPerPage
    }
    globalContent {
        globalContent {
            hubspotContactFormId
            title404
            pretitle404
            content404
            addresses {
                address
                fieldGroupName
            }
            facebook
            footerMenuLinks {
                link {
                    target
                    title
                    url
                }
            }
            secondaryFooterLinks {
                link {
                    target
                    title
                    url
                }
            }
            fieldGroupName
            headerLinks {
                fieldGroupName
                link {
                    target
                    title
                    url
                }
                childLinks {
                    fieldGroupName
                    link {
                        target
                        title
                        url
                    }
                }
            }
            linkedin
            twitter
            youtube
            instagram
            offcanvasParentLinks {
                childLinks {
                    fieldGroupName
                    link {
                        target
                        title
                        url
                    }
                }
                fieldGroupName
                link {
                    target
                    title
                    url
                }
            }
            suggestedSearches {
                searchQuery
            }
            categories {
                parentCategories {
                    applicablePostTypes
                    categoryTitle
                    categoryIdentifier
                }
                subcategories {
                    subcategoryTitle
                    subcategoryIdentifier
                }
            }
            globalResourceHubModalTitle
            globalResourceHubModalContent
        }
    }
}`;

export default globalContentQuery;
