export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0"},{"name":"format-detection","content":"telephone=no"}],"link":[],"style":[],"script":[{"hid":"fa","src":"https://kit.fontawesome.com/43f384c360.js","defer":true,"crossorigin":"anonymous"},{"src":"https://cdn.cookielaw.org/consent/f58341b3-5c3b-4bb0-b268-bdd4b37a9064/OtAutoBlock.js","type":"text/javascript"},{"src":"https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js","type":"text/javascript"},{"src":"//js.hs-scripts.com/302795.js","type":"text/javascript","id":"hs-script-loader","async":true,"defer":true},{"src":"https://cdn.cookielaw.org/consent/f58341b3-5c3b-4bb0-b268-bdd4b37a9064/otSDKStub.js","type":"text/javascript","charset":"UTF-8","data-domain-script":"f58341b3-5c3b-4bb0-b268-bdd4b37a9064"},{"type":"text/javascript","innerHTML":"function OptanonWrapper() { }"}],"noscript":[],"htmlAttrs":{"lang":"en-GB"},"title":"Cisilion | Next Generation IT Infrastructure"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null