import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordions, LazyAwards, LazyBanner, LazyBlockquote, LazyClientStories, LazyComparison, LazyCtaBlock, LazyForm, LazyHeroVideo, LazyIndustries, LazyItemScroller, LazyJobRoles, LazyLoader, LazyLogos, LazyMap, LazySbsText, LazySbsTextColumns, LazySbsTextSlider, LazySectionBanner, LazySlidingCarousel, LazySolutions, LazyStats, LazyTeam, LazyVerticalSlider, LazyVideo, LazyWindowBanner, LazyWysiwyg, LazyGlobalCursor, LazyGlobalFooter, LazyGlobalHeader, LazyGlobalOffcanvasMenu, LazyGlobalSearch, LazyHubspotContactForm, LazyHubspotHeader, LazyHubspotLoading, LazyHubspotSent, LazyPartialsArticle, LazyPartialsFilters, LazyPartialsMenu, LazyPartialsOffCanvas, LazyPartialsPagination, LazyPartialsPaginationAnchor, LazySvgoIconArrowRightMineral, LazySvgoIconArrowRightObsidian, LazySvgoIconArrowRightWhite, LazySvgoIconArticle, LazySvgoIconChevronDownWhite, LazySvgoIconChevronDown, LazySvgoIconChevronUp, LazySvgoIconCloseObsidian, LazySvgoIconCloseWhite, LazySvgoIconClose, LazySvgoIconCloudDownload, LazySvgoIconFacebook, LazySvgoIconFilter, LazySvgoIconInstagram, LazySvgoIconLinkedinSolo, LazySvgoIconLinkedin, LazySvgoIconMenu, LazySvgoIconPin, LazySvgoIconPlay, LazySvgoIconScroll, LazySvgoIconSearch, LazySvgoIconTwitter, LazySvgoIconXCircle, LazySvgoIconX, LazySvgoIconYoutube, LazySvgoLogoIcon, LazySvgoLogoRepeat, LazySvgoLogo } from '#components'
const lazyGlobalComponents = [
  ["Accordions", LazyAccordions],
["Awards", LazyAwards],
["Banner", LazyBanner],
["Blockquote", LazyBlockquote],
["ClientStories", LazyClientStories],
["Comparison", LazyComparison],
["CtaBlock", LazyCtaBlock],
["Form", LazyForm],
["HeroVideo", LazyHeroVideo],
["Industries", LazyIndustries],
["ItemScroller", LazyItemScroller],
["JobRoles", LazyJobRoles],
["Loader", LazyLoader],
["Logos", LazyLogos],
["Map", LazyMap],
["SbsText", LazySbsText],
["SbsTextColumns", LazySbsTextColumns],
["SbsTextSlider", LazySbsTextSlider],
["SectionBanner", LazySectionBanner],
["SlidingCarousel", LazySlidingCarousel],
["Solutions", LazySolutions],
["Stats", LazyStats],
["Team", LazyTeam],
["VerticalSlider", LazyVerticalSlider],
["Video", LazyVideo],
["WindowBanner", LazyWindowBanner],
["Wysiwyg", LazyWysiwyg],
["GlobalCursor", LazyGlobalCursor],
["GlobalFooter", LazyGlobalFooter],
["GlobalHeader", LazyGlobalHeader],
["GlobalOffcanvasMenu", LazyGlobalOffcanvasMenu],
["GlobalSearch", LazyGlobalSearch],
["HubspotContactForm", LazyHubspotContactForm],
["HubspotHeader", LazyHubspotHeader],
["HubspotLoading", LazyHubspotLoading],
["HubspotSent", LazyHubspotSent],
["PartialsArticle", LazyPartialsArticle],
["PartialsFilters", LazyPartialsFilters],
["PartialsMenu", LazyPartialsMenu],
["PartialsOffCanvas", LazyPartialsOffCanvas],
["PartialsPagination", LazyPartialsPagination],
["PartialsPaginationAnchor", LazyPartialsPaginationAnchor],
["SvgoIconArrowRightMineral", LazySvgoIconArrowRightMineral],
["SvgoIconArrowRightObsidian", LazySvgoIconArrowRightObsidian],
["SvgoIconArrowRightWhite", LazySvgoIconArrowRightWhite],
["SvgoIconArticle", LazySvgoIconArticle],
["SvgoIconChevronDownWhite", LazySvgoIconChevronDownWhite],
["SvgoIconChevronDown", LazySvgoIconChevronDown],
["SvgoIconChevronUp", LazySvgoIconChevronUp],
["SvgoIconCloseObsidian", LazySvgoIconCloseObsidian],
["SvgoIconCloseWhite", LazySvgoIconCloseWhite],
["SvgoIconClose", LazySvgoIconClose],
["SvgoIconCloudDownload", LazySvgoIconCloudDownload],
["SvgoIconFacebook", LazySvgoIconFacebook],
["SvgoIconFilter", LazySvgoIconFilter],
["SvgoIconInstagram", LazySvgoIconInstagram],
["SvgoIconLinkedinSolo", LazySvgoIconLinkedinSolo],
["SvgoIconLinkedin", LazySvgoIconLinkedin],
["SvgoIconMenu", LazySvgoIconMenu],
["SvgoIconPin", LazySvgoIconPin],
["SvgoIconPlay", LazySvgoIconPlay],
["SvgoIconScroll", LazySvgoIconScroll],
["SvgoIconSearch", LazySvgoIconSearch],
["SvgoIconTwitter", LazySvgoIconTwitter],
["SvgoIconXCircle", LazySvgoIconXCircle],
["SvgoIconX", LazySvgoIconX],
["SvgoIconYoutube", LazySvgoIconYoutube],
["SvgoLogoIcon", LazySvgoLogoIcon],
["SvgoLogoRepeat", LazySvgoLogoRepeat],
["SvgoLogo", LazySvgoLogo]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
