import { register } from 'swiper/element/bundle';
import { useDateFormat } from '@vueuse/core';
import { useMainStore } from '~/store';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('page:finish', () => {
        document.querySelectorAll('.js-header__primary-nav-parent').forEach((el) => {
            el.classList.remove('is-active');
        });
    });

    return {
        provide: {            
            convertUrl: (url: string) => {
                const parts = url.split('/');

                if(parts[2] == 'cisilion.test' && !url.includes('/app')) {
                    url = url
                        .replace('https', 'http')
                        .replace('cisilion.test', 'localhost:3000');
                // Strip out /cms for urls that aren't media items
                } else if(url.includes('cms/') && !url.includes('/app/uploads')) {
                    url = url.replace('cms/', '');
                };

                // Make sure urls that don't end in a trailing slash have a trailing slash
                if (url.charAt(url.length - 1) !== '/' && !url.includes('/app/uploads')) {
                    // Add a slash to the end of the URL
                    url = url + '/';
                };

                return url;
            },
            convertVideoId: (video: string) => {
                // Get YouTube Video ID from URL
                // https://stackoverflow.com/a/8260383

                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                const match = video.match(regExp);

                return (match&&match[7].length==11)? match[7] : false;
            },
            instantiateSwiper: register(),
            formatDate: (date: string) => {
                return useDateFormat(date, 'DD/MM/YYYY')
            },
            formatComponentName: (name: string, type: string) => {
                let prefix = `${type}_Components_Components_`;

                let newName = name.startsWith(prefix)
                ? name.substring(prefix.length)
                : name;

                return newName;
            },
            getGlobalData: () => {
                return useMainStore().globalData;
            },
            getGlobalContent: () => {
                return useMainStore().globalData.globalContent.globalContent;
            }
        }
    }
})
