import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import performance_client_OXWUsvTdFu from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/performance.client.js";
import plugin_adVF2uRka6 from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import InlineSvg_client_NRw9DaDp0I from "/app/plugins/InlineSvg.client.ts";
import VimeoPlayer_client_SP1xhQXjNL from "/app/plugins/VimeoPlayer.client.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/app/plugins/Vue3Lottie.client.ts";
import aos_client_rDSYU13oOX from "/app/plugins/aos.client.ts";
import helpers_mgWmzbOuLB from "/app/plugins/helpers.ts";
import pinia_48xmdi2HHl from "/app/plugins/pinia.ts";
import youtube_client_RwCHrRW0F5 from "/app/plugins/youtube.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  performance_client_OXWUsvTdFu,
  plugin_adVF2uRka6,
  plugin_tbFNToZNim,
  plugin_8SbxDRbG6Y,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  InlineSvg_client_NRw9DaDp0I,
  VimeoPlayer_client_SP1xhQXjNL,
  Vue3Lottie_client_bMZiOS6AB0,
  aos_client_rDSYU13oOX,
  helpers_mgWmzbOuLB,
  pinia_48xmdi2HHl,
  youtube_client_RwCHrRW0F5
]