import { default as _91_46_46_46page_93LN8p5ClGpLMeta } from "/app/pages/[...page].vue?macro=true";
import { default as _91_46_46_46post_93Ti7cSaozbVMeta } from "/app/pages/client-stories/[...post].vue?macro=true";
import { default as index0WO5xNUTGDMeta } from "/app/pages/client-stories/index.vue?macro=true";
import { default as _91_46_46_46post_93NTfJgpcoz4Meta } from "/app/pages/events/[...post].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46post_93zU1jTvwvBrMeta } from "/app/pages/news-blog/[...post].vue?macro=true";
import { default as index5ZDcjAgQs4Meta } from "/app/pages/news-blog/index.vue?macro=true";
import { default as _91page_93HVuygmK3VIMeta } from "/app/pages/news-blog/page/[page].vue?macro=true";
import { default as indexZTZzoTQaX8Meta } from "/app/pages/resource-hub/index.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as style_45guideXysrZx05lnMeta } from "/app/pages/style-guide.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93LN8p5ClGpLMeta?.name ?? "page",
    path: _91_46_46_46page_93LN8p5ClGpLMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93LN8p5ClGpLMeta || {},
    alias: _91_46_46_46page_93LN8p5ClGpLMeta?.alias || [],
    redirect: _91_46_46_46page_93LN8p5ClGpLMeta?.redirect || undefined,
    component: () => import("/app/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46post_93Ti7cSaozbVMeta?.name ?? "client-stories-post",
    path: _91_46_46_46post_93Ti7cSaozbVMeta?.path ?? "/client-stories/:post(.*)*",
    meta: _91_46_46_46post_93Ti7cSaozbVMeta || {},
    alias: _91_46_46_46post_93Ti7cSaozbVMeta?.alias || [],
    redirect: _91_46_46_46post_93Ti7cSaozbVMeta?.redirect || undefined,
    component: () => import("/app/pages/client-stories/[...post].vue").then(m => m.default || m)
  },
  {
    name: index0WO5xNUTGDMeta?.name ?? "client-stories",
    path: index0WO5xNUTGDMeta?.path ?? "/client-stories",
    meta: index0WO5xNUTGDMeta || {},
    alias: index0WO5xNUTGDMeta?.alias || [],
    redirect: index0WO5xNUTGDMeta?.redirect || undefined,
    component: () => import("/app/pages/client-stories/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46post_93NTfJgpcoz4Meta?.name ?? "events-post",
    path: _91_46_46_46post_93NTfJgpcoz4Meta?.path ?? "/events/:post(.*)*",
    meta: _91_46_46_46post_93NTfJgpcoz4Meta || {},
    alias: _91_46_46_46post_93NTfJgpcoz4Meta?.alias || [],
    redirect: _91_46_46_46post_93NTfJgpcoz4Meta?.redirect || undefined,
    component: () => import("/app/pages/events/[...post].vue").then(m => m.default || m)
  },
  {
    name: indexWgIHVzns1AMeta?.name ?? "events",
    path: indexWgIHVzns1AMeta?.path ?? "/events",
    meta: indexWgIHVzns1AMeta || {},
    alias: indexWgIHVzns1AMeta?.alias || [],
    redirect: indexWgIHVzns1AMeta?.redirect || undefined,
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46post_93zU1jTvwvBrMeta?.name ?? "news-blog-post",
    path: _91_46_46_46post_93zU1jTvwvBrMeta?.path ?? "/news-blog/:post(.*)*",
    meta: _91_46_46_46post_93zU1jTvwvBrMeta || {},
    alias: _91_46_46_46post_93zU1jTvwvBrMeta?.alias || [],
    redirect: _91_46_46_46post_93zU1jTvwvBrMeta?.redirect || undefined,
    component: () => import("/app/pages/news-blog/[...post].vue").then(m => m.default || m)
  },
  {
    name: index5ZDcjAgQs4Meta?.name ?? "news-blog",
    path: index5ZDcjAgQs4Meta?.path ?? "/news-blog",
    meta: index5ZDcjAgQs4Meta || {},
    alias: index5ZDcjAgQs4Meta?.alias || [],
    redirect: index5ZDcjAgQs4Meta?.redirect || undefined,
    component: () => import("/app/pages/news-blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93HVuygmK3VIMeta?.name ?? "news-blog-page-page",
    path: _91page_93HVuygmK3VIMeta?.path ?? "/news-blog/page/:page()",
    meta: _91page_93HVuygmK3VIMeta || {},
    alias: _91page_93HVuygmK3VIMeta?.alias || [],
    redirect: _91page_93HVuygmK3VIMeta?.redirect || undefined,
    component: () => import("/app/pages/news-blog/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexZTZzoTQaX8Meta?.name ?? "resource-hub",
    path: indexZTZzoTQaX8Meta?.path ?? "/resource-hub",
    meta: indexZTZzoTQaX8Meta || {},
    alias: indexZTZzoTQaX8Meta?.alias || [],
    redirect: indexZTZzoTQaX8Meta?.redirect || undefined,
    component: () => import("/app/pages/resource-hub/index.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect || undefined,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: style_45guideXysrZx05lnMeta?.name ?? "style-guide",
    path: style_45guideXysrZx05lnMeta?.path ?? "/style-guide",
    meta: style_45guideXysrZx05lnMeta || {},
    alias: style_45guideXysrZx05lnMeta?.alias || [],
    redirect: style_45guideXysrZx05lnMeta?.redirect || undefined,
    component: () => import("/app/pages/style-guide.vue").then(m => m.default || m)
  }
]