import { useMainStore } from '~/store'

export default defineNuxtPlugin(async ({ $pinia }) => {

    const store = useMainStore($pinia);

    return {
        provide: {
            store
        }
    }
})