<template>
    <footer class="o-footer">
        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 medium-2 cell">
                    <a href="/" class="o-footer__logo" data-aos="fade-up" title="Visit homepage">
                        <SvgoLogo />
                    </a>
                </div>

                <div class="small-12 medium-9 medium-offset-1 large-6 large-offset-4 cell">
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 medium-9 large-8 cell">
                            <div class="o-footer__addresses-wrapper">
                                <div class="o-footer__section-title">
                                    Contact
                                </div>

                                <div v-if="$getGlobalContent()" class="o-footer__addresses">
                                    <div v-html="address['address']" v-for="(address, index) in $getGlobalContent().addresses" class="o-footer__address"></div>
                                </div>
                            </div>
                        </div>

                        <div class="small-12 medium-3 large-offset-1 large-3 cell">
                            <div class="o-footer__company">
                                <div class="o-footer__section-title">
                                    Company
                                </div>

                                <nav v-if="$getGlobalContent()" class="o-footer__company-links">
                                    <ul>
                                        <li v-for="(link, index) in $getGlobalContent().footerMenuLinks">
                                            <NuxtLink
                                                :to="$convertUrl(link.link.url)"
                                                :target="link.link.target">
                                                <span v-html="link.link.title"></span>
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div v-if="$getGlobalContent()" class="o-footer__social">
                        <NuxtLink :to="$getGlobalContent().linkedin" class="c-social-icon c-social-icon--linkedin" title="Follow Cisilion on LinkedIn" target="_blank" v-if="$getGlobalContent().linkedin">
                            <SvgoIconLinkedin />
                        </NuxtLink>

                        <NuxtLink :to="$getGlobalContent().twitter" class="c-social-icon c-social-icon--x" title="Follow Cisilion on X" target="_blank" v-if="$getGlobalContent().twitter">
                            <SvgoIconXCircle />
                        </NuxtLink>

                        <NuxtLink :to="$getGlobalContent().facebook" class="c-social-icon c-social-icon--facebook" title="Follow Cisilion on Facebook" target="_blank" v-if="$getGlobalContent().facebook">
                            <SvgoIconFacebook />
                        </NuxtLink>

                        <NuxtLink :to="$getGlobalContent().youtube" class="c-social-icon c-social-icon--youtube" title="Follow Cisilion on YouTube" target="_blank" v-if="$getGlobalContent().youtube">
                            <SvgoIconYoutube />
                        </NuxtLink>

                        <NuxtLink :to="$getGlobalContent().instagram" class="c-social-icon c-social-icon--instagram" title="Follow Cisilion on Instagram" target="_blank" v-if="$getGlobalContent().instagram">
                            <SvgoIconInstagram />
                        </NuxtLink>

                        <div class="o-footer__logo-icon">
                            <SvgoLogoIcon />
                        </div>
                    </div>

                    <nav v-if="$getGlobalContent()" class="o-footer__ancillary-nav">
                        <ul>
                            <li v-for="(link, index) in $getGlobalContent().secondaryFooterLinks">
                                <NuxtLink
                                    :to="$convertUrl(link.link.url)"
                                    :target="link.link.target">
                                    <span v-html="link.link.title"></span>
                                </NuxtLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

</script>
