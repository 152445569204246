import { defineStore } from 'pinia'
import globalContentQuery from '../queries/global';
import eventsQuery from '../queries/loops/events';

export const useMainStore = defineStore('main', {
    state: () => ({
        globalData: {},
        isMenuOpen: false,
        contactMenu: false,
        contactFormType: '',
        hubspotId: '',
        isSearchOpen: false,
        searchActiveClass: '',
        searchActiveButtonClass: '',
        eventFormHeader: {},
        latestEvent: {},
        mostRecentEvent: {},
        headerTheme: ''
    }),
    actions: {
        setHeaderTheme(theme) {
            this.headerTheme = theme
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        toggleSearch() {
            const $searchInput = document.querySelectorAll('.js-search__input')[0];

            if (this.isSearchOpen) {
                this.searchActiveClass = '';
                this.searchActiveButtonClass = '';

                document.querySelectorAll('.js-search__suggested-searches')[0].classList.remove('is-active');
                $searchInput.blur();

                setTimeout(() => {
                    this.isSearchOpen = false;
                }, 500);
            } else {
                this.isSearchOpen = true;

                setTimeout(() => {
                    this.searchActiveClass = 'is-active';
                }, 100);

                setTimeout(() => {
                    this.searchActiveButtonClass = 'is-visible';
                }, 500);

                nextTick(() => {
                    $searchInput.focus();
                });
            }
        },
        eventHeader(eventData) {
            this.eventFormHeader = eventData
        },
        openContact(formtype, formId) {
            this.contactMenu = true;
            this.contactFormType = formtype;
            this.hubspotId = formId;
        },
        closeContact() {
            this.contactMenu = false;
            this.contactFormType = '';
        },
        async getGlobalData() {
            const query = gql(globalContentQuery);
            const { data, error } = await useAsyncQuery(query)
            if(data.value) {
                this.globalData = data.value;
            }
        },
        async getLatestEvent() {
            const query = gql(eventsQuery('Future', 1, null, null));
            const { data, error } = await useAsyncQuery(query);

            if(data.value.events.edges.length) {
                this.latestEvent = data.value.events.edges[0].node;
            };
        },
        async getMostRecentEvent() {
            const query = gql(eventsQuery('Past', 1, null, null));
            const { data, error } = await useAsyncQuery(query);

            if(data.value.events.edges.length) {
                this.mostRecentEvent = data.value.events.edges[0].node;
            };
        }
    },
})
