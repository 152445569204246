<template>
    <div class="loading-form">
      <span></span>
      <div class="loading-row"></div>
      <span></span>
      <div class="loading-row"></div>
      <span></span>
      <div class="loading-row"></div>
      <span></span>
      <div class="loading-row big"></div>
      <div class="loading-row small"></div>
    </div>
  </template>
  
  <style>
  .loading-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
        max-width: 100px;
        height: 22px;
        background-color: #ccc; /* Grey color */
        animation: loadingAnimation 1.5s infinite;
    }

  }

  .loading-row {
    height: 44px;
    width: 100%;
    background-color: #ccc; /* Grey color */
    animation: loadingAnimation 1.5s infinite;
    margin-bottom: 44px;
    &.big {
        min-height: 250px;
    }
    &.small {
        max-width: 100px;
    }
  }
  
  @keyframes loadingAnimation {
    0%, 100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }
  </style>